import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons/faHeart";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons/faHeart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import deepmerge from "ramda/src/mergeDeepRight";
import { memo, useCallback, useMemo } from "react";
import { setLiked as setLikedRoutine } from "routines/likes";

import RatingCount from "components/Entities/Page/RatingCount";
import RatingText from "components/Entities/Page/RatingText";

import accessibleClickProps from "../../utils/misc/accessibleClickProps";

import { selectAuthLoggedIn } from "selectors/auth";
import generateTransition from "utils/generateTransition";

import useActionCreators from "hooks/useActionCreators";
import useActivityContext from "hooks/useActivityContext";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";
import ScreenSizes from "styles/ScreenSizes";

const iconStyles = {
  fontSize: 13,
  transformOrigin: "50% 50%",
  transition: generateTransition({
    target: "visual",
    targets: ["margin", "transform"],
    speed: "100ms",
  }),
  lineHeight: "1em",
};

const psuedoStyles = {
  showOutline: {
    ":not(fake) .iconHeartUnactioned": {
      opacity: 1,
    },
    ":not(fake) .iconHeartActioned": {
      opacity: 0,
    },
    ":not(fake) .iconNotch": {
      opacity: 0,
    },
  },
  showFilled: {
    ":not(fake) .iconHeartUnactioned": {
      opacity: 0,
    },
    ":not(fake) .iconHeartActioned": {
      opacity: 1,
    },
    ":not(fake) .iconNotch": {
      opacity: 0,
    },
  },
  showNotch: {
    ":not(fake) .iconHeartUnactioned": {
      opacity: 0,
    },
    ":not(fake) .iconHeartActioned": {
      opacity: 0,
    },
    ":not(fake) .iconNotch": {
      opacity: 1,
    },
  },
  showHoverNotch: {
    ...getHoverQuery(
      {
        opacity: 0,
      },
      ":hover .iconHeartUnactioned"
    ),
    ...getHoverQuery(
      {
        opacity: 0,
      },
      ":hover .iconHeartActioned"
    ),
    ...getHoverQuery(
      {
        opacity: 1,
      },
      ":hover .iconNotch"
    ),
  },
  showHoverFilled: {
    ...getHoverQuery(
      {
        opacity: 0,
      },
      ":hover .iconHeartUnactioned"
    ),
    ...getHoverQuery(
      {
        opacity: 1,
      },
      ":hover .iconHeartActioned"
    ),
    ...getHoverQuery(
      {
        opacity: 0,
      },
      ":hover .iconNotch"
    ),
  },
};

const baseStyles = {
  likesContainer: {},
  likesContainerHorizontal: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  likesContainerWithRatingsText: {
    display: "flex",
    alignItems: "center",
  },
  likes: {
    flex: "inherit",
    whiteSpace: "pre",
    fontSize: "0.7rem",
    flexGrow: "1",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: colours.black,

    [ScreenSizes.lgAndAbove]: {
      fontSize: "0.8rem",
      flex: "0 0 65%",
      whiteSpace: "nowrap",
    },
  },
  likesWithRatingsText: {
    marginRight: "0.5rem",
  },
  boxedLikes: {
    background: colours.offWhite,
    borderRadius: 4,
    padding: "0.4em 0.6em 0.4em",
    border: "1px solid #ededed",
    cursor: "pointer",

    ...getHoverQuery({
      background: "#f9f9f9",
      borderColor: "#f3f3f3",
    }),
    ...getHoverQuery(
      {
        transform: "scale(1.2,1.2)",
      },
      ":hover .boxedIcon"
    ),
    [ScreenSizes.lgAndAbove]: {
      flex: "1 1 auto",
    },
    ...psuedoStyles.showHoverFilled,
  },
  boxedUserLikedLikes: {
    background: colours.actioned,
    borderColor: colours.actioned,

    ...getHoverQuery({
      background: colours.actioned,
      borderColor: colours.actioned,
    }),
    ...getHoverQuery(
      {
        transform: "scale(0.9,0.9)",
        color: "#fff", // colours.black,
      },
      ":hover .boxedIcon"
    ),
  },
  likesSaving: {
    ...psuedoStyles.showNotch,
    ...psuedoStyles.showHoverNotch,
    ...getHoverQuery(
      {
        transform: "scale(1,1)",
      },
      ":hover .boxedIcon"
    ),
  },
  icon: {
    ...iconStyles,
    color: colours.greyishBlue, // colours.primary,
    cursor: "pointer",
    fontSize: "1rem",
  },
  iconHorizontal: {
    marginLeft: ".6rem",
  },
  filledIcon: {
    ...psuedoStyles.showFilled,
  },
  filledIconSaving: {
    ...psuedoStyles.showNotch,
  },
  boxedIcon: {},
  userLikedIcon: {
    ":not(fake) .iconHeartActioned": {
      color: "#fff",
    },
    ":not(fake) .iconNotch": {
      color: colours.newMidDarkGrey,
    },
  },
  boxedUserLikedIcon: {
    ...psuedoStyles.showFilled,
  },
  iconSaving: {},
  iconHeartUnactioned: {
    opacity: 1,
  },
  iconHeartActioned: {
    position: "absolute",
    opacity: 0,
    color: colours.negative,
  },
  iconHeartPink: {
    color: colours.primary,
  },
  iconHeartGrey: {
    color: colours.greyishBlue,
  },
  iconHeartLiked: {
    color: colours.actioned,
  },
  iconNotch: {
    position: "absolute",
    opacity: 0,
    color: colours.black,
    maxWidth: "1.5em",
  },
};

const pinkLikesRatingStyles = {
  textContainer: {
    ...gStyles.fontBold,
    color: colours.black,
    fontSize: ".625rem",
    lineHeight: "1.1rem",
  },
};

const horizontalLikeRatingStyles = {
  textContainer: {
    marginTop: 0,
  },
};

const EntityLikes = (props) => {
  const {
    entity,
    entity_type,
    entityName,
    onClose,
    showRatingsText,
    showRatingsCount,
    entityTitle,
    boxed,
    likesRatingStyles,
    pink,
    grey,
    horizontal,
    filled,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const { setLiked } = useActionCreators({ setLiked: setLikedRoutine });

  const isLoggedIn = useReduxState((state) => selectAuthLoggedIn(state), []);

  const feedActivityContext = useActivityContext();

  const like_saving = entity.getIn(["user_data", "like_saving"]);
  const userLiked = entity.getIn(["user_data", "is_liked"]);
  const like_count = entity.get("like_count");

  const textStyles = useMemo(
    () =>
      deepmerge(
        pinkLikesRatingStyles,
        // pink ? pinkLikesRatingStyles : {},
        horizontal ? horizontalLikeRatingStyles : {},
        likesRatingStyles || {}
      ),
    [likesRatingStyles, horizontal]
  );

  const handleLike = useCallback(
    (e) => {
      if (!isLoggedIn && onClose) {
        onClose();
      }

      e.preventDefault();
      setLiked({
        entity_id: entity.get("id"),
        entity_type,
        entityTitle,
        entity: entityTitle ? null : entity,
        liked: !userLiked,
        analyticsVariables: feedActivityContext,
      });
    },
    [
      isLoggedIn,
      onClose,
      setLiked,
      entity,
      entity_type,
      entityTitle,
      userLiked,
      feedActivityContext,
    ]
  );

  return (
    <div
      className={css(
        styles.likesContainer,
        horizontal && styles.likesContainerHorizontal,
        showRatingsText && styles.likesContainerWithRatingsText
      )}
    >
      <div
        className={css(
          styles.likes,
          boxed && styles.boxedLikes,
          boxed && userLiked && styles.boxedUserLikedLikes,
          showRatingsText && styles.likesWithRatingsText,
          like_saving && styles.likesSaving
        )}
        {...accessibleClickProps(like_saving ? undefined : handleLike)}
      >
        <span
          className={`${css(
            styles.icon,
            horizontal && styles.iconHorizontal,
            boxed && styles.boxedIcon,
            !pink && !grey && userLiked && styles.userLikedIcon,
            userLiked && boxed && styles.boxedUserLikedIcon,
            (filled || userLiked) && styles.filledIcon,
            filled && like_saving && styles.filledIconSaving,
            like_saving && styles.iconSaving
          )} ${boxed && "boxedIcon"}`}
        >
          <FontAwesomeIcon
            icon={faCircleNotch}
            spin
            className={`${css(styles.iconNotch)} iconNotch`}
          />
          <FontAwesomeIcon
            icon={faHeartSolid}
            className={`${css(
              styles.iconHeartActioned,
              pink && styles.iconHeartPink,
              grey && styles.iconHeartGrey,
              userLiked && styles.iconHeartLiked
            )} iconHeartActioned`}
          />
          <FontAwesomeIcon
            icon={faHeartRegular}
            className={`${css(
              styles.iconHeartUnactioned,
              pink && styles.iconHeartPink,
              grey && styles.iconHeartGrey
            )} iconHeartUnactioned`}
          />
        </span>
      </div>
      {showRatingsText && (
        <RatingText
          entityName={entityName || entity_type}
          saving={like_saving}
          ratedByUser={userLiked}
          ratingCount={like_count}
          friendRatingCount={entity.get("friend_like_count")}
          ratedText="liked"
          styles={textStyles}
        />
      )}
      {showRatingsCount && (
        <RatingCount
          entityName={entityName || entity_type}
          saving={like_saving}
          ratedByUser={userLiked}
          ratingCount={like_count}
          friendRatingCount={entity.get("friend_like_count")}
          ratedText="liked"
          styles={textStyles}
        />
      )}
    </div>
  );
};

EntityLikes.propTypes = {
  entity: PropTypes.instanceOf(Map).isRequired,
  entity_type: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  entityName: PropTypes.string,
  showRatingsText: PropTypes.bool,
  showRatingsCount: PropTypes.bool,
  entityTitle: PropTypes.string,
  boxed: PropTypes.bool,
  likesRatingStyles: PropTypes.object,
  pink: PropTypes.bool,
  grey: PropTypes.bool,
  horizontal: PropTypes.bool,
  filled: PropTypes.bool,
};
EntityLikes.defaultProps = {
  onClose: null,
  entityName: null,
  showRatingsText: false,
  showRatingsCount: false,
  entityTitle: null,
  boxed: false,
  likesRatingStyles: null,
  pink: false,
  grey: false,
  horizontal: false,
  filled: false,
};

export default memo(EntityLikes);
